import React, { useEffect, useState } from 'react'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import Container from '../components/container'
import CloudIndexNav from '../components/cloudIndexNav/cloudIndexNav'
// import CloudIndexResources from '../../components/cloudIndexResources/cloudIndexResources'
import Button from '../components/button/button'
// import PdfViewer from '../../components/pdfViewer'

import * as global from '../components/globals.module.css'
import * as styles from './infrared/infra.module.css'
import { graphql, Link } from 'gatsby'
import linkStyle from '../styles/components/links.module.css'
import Icon from '../components/icons'
import { Image } from '../components/Image'

export const query = graphql`
    query radar {
        page: sanityPage(title: { eq: "Radar" }) {
            title
            _rawBody
            metaTitle
            metaDescription
            metaKeywords
            metaImage {
                asset {
                    _id
                    url
                }
            }
                radar {
                    mainImage {
                        asset {
                            _id
                        }
                    }
                    googleDriveLink
                }
            }
        }
`

const CloudReportPage = props => {
  const { data, errors } = props

  const [radar, setRadar] = useState(null)

  const report = data.page?.radar

  function previewLink (viewLink) {
    if (!viewLink) return null
    if (viewLink.includes('/view')) {
      return viewLink.replace('/view', '/preview')
    } else if (viewLink.includes('/preview')) {
      return viewLink + '/preview'
    }
  }

  function downloadLink (viewLink) {
    if (!viewLink) return null
    const fileIdMatch = viewLink.match(/\/d\/([a-zA-Z0-9_-]+)\//)
    if (fileIdMatch && fileIdMatch[1]) {
      const fileId = fileIdMatch[1]
      return `https://drive.google.com/uc?export=download&id=${fileId}`
    } else {
      return viewLink
    }
  }

  function trackDownload (report) {
    if (!report) return
    if (typeof gtag !== 'undefined') {
      gtag('event', `download the report ${report}`, {
        component: 'button'
      })
    }
  }

  useEffect(() => {
    setRadar(report)
  }, [report])

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  return (
    <Layout page='cloudReport' hideContactBar version='1'>
      <SEO title={data?.page?.metaTitle || data?.page?.title} description={data?.page?.metaDescription} keywords={data?.page?.metaKeywords ? data?.page?.metaKeywords.split(',') : []} imageFB={data?.page?.metaImage?.asset.url || ''} imageTW={data?.page?.metaImage?.asset.url || ''} />

      <CloudIndexNav hide />

      <div className={global.offwhite} style={{ paddingTop: '60px' }}>
        <Container class={styles.container}>
          {radar?.mainImage.asset && (
            <h1>
              <Image imgObj={radar.mainImage} props={{ alt: 'The InfraRed Radar' }} className={styles.radarHeader} />
            </h1>
          )}

          {radar && (
            <iframe
              className={styles.pdf} src={previewLink(radar.googleDriveLink)}
              allow='autoplay'
            />
          )}

          {radar && (
            <div className={styles.mobilePdf}>
              <Link className={linkStyle.linkArrow} to={downloadLink(radar.googleDriveLink)} onClick={() => trackDownload('Radar')}>Download the report
                <span>
                  <div className={linkStyle.iconCircle} />
                  <div className={linkStyle.icon}>
                    <Icon symbol='newsArrowIcon' color='currentColor' />
                  </div>
                  <div className={linkStyle.iconSecondary}>
                    <Icon symbol='newsArrowIcon' color='currentColor' />
                  </div>
                </span>
              </Link>
            </div>
          )}

        </Container>
      </div>

      {/* <CloudIndexResources withWave={true} /> */}

    </Layout>
  )
}

export default CloudReportPage
